import {
  Button,
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";
import logo from 'assets/img/logo.svg'
import HeaderContent from "Header/HeaderContent";
import { Header } from "Header/Header";

function App() {
  return (
    <Header />
  );
}

export default App;

import {
    Button,
    UncontrolledCollapse,
    NavbarBrand,
    Navbar,
    NavItem,
    NavLink,
    Nav,
    Container,
    Row,
    Col,
  } from "reactstrap";import logo from 'assets/img/logo.svg'
import HeaderContent from "./HeaderContent";


export const Header = () => {
return (
    <div className="header header-3">
    <Navbar className="navbar-transparent navbar-absolute" expand="lg">
      <Container>
        <div className="navbar-translate">
          <button className="navbar-toggler" id="example-header-3">
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
          <NavbarBrand
              href="/"
              onClick={(e) => e.preventDefault()}
            >
                    <img
        alt="logo"
        src={logo}
        style={{
          height: 100,
          width: 350
        }}
      />
            </NavbarBrand>
        </div>
        <UncontrolledCollapse navbar toggler="#example-header-3">
          <div className="navbar-collapse-header">
            <Row>
              <Col className="collapse-brand" xs="6">
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  Evergreen Coast<span></span>
                </a>
              </Col>
              <Col className="collapse-close text-right" xs="6">
                <button className="navbar-toggler" id="example-header-3">
                  <i className="tim-icons icon-simple-remove" />
                </button>
              </Col>
            </Row>
          </div>
          <Nav className="ml-auto" navbar>
          <NavItem className="active">
                <NavLink
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  Home
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  About Us
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  Pricing
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  Contact Us
                </NavLink>
              </NavItem>
          </Nav>
        </UncontrolledCollapse>
      </Container>
    </Navbar>
    
   <HeaderContent />
    
  </div>
)
    }
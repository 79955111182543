import {
    Button,
    UncontrolledCollapse,
    NavbarBrand,
    Navbar,
    NavItem,
    NavLink,
    Nav,
    Container,
    Row,
    Col,
    Input,
  } from "reactstrap";
  import Slick from "react-slick";
import { InfoArea } from "./InfoArea";

// custom previous button for the slick component
const PrevButton = (props) => {
  return (
    <Button
      className="btn-round btn-icon btn-simple slick-prev slick-arrow"
      color="success"
      aria-label="Previous"
      type="button"
      onClick={props.onClick}
    >
      <i className="tim-icons icon-minimal-left" />
    </Button>
  );
};
// custom next button for the slick component
const NextButton = (props) => {
  return (
    <Button
      className="btn-round btn-icon btn-simple slick-next slick-arrow"
      color="success"
      aria-label="Next"
      type="button"
    >
      <i className="tim-icons icon-minimal-right" onClick={props.onClick} />
    </Button>
  );
};

  let slickHeader3Settings = {
    dots: false,
    infinite: true,
    centerMode: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    // adaptiveHeight: true,
    // variableWidth: true,
    prevArrow: <PrevButton />,
    nextArrow: <NextButton />,
    className: "center slider slick-buttons-under",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  };

export default function HeaderContent() {
    return (

      <div className="page-header header-video header-filter">
        <div className="overlay" />
        <video
          autoPlay="autoplay"
          loop="loop"
          muted="muted"
          playsInline="playsinline"
        >
          <source
            src={require("assets/video/background.mov")}
            type="video/mp4"
          />
        </video>
            <div className="content-center">
              <Row>
                <Col
                  className="ml-auto mr-auto positioned"
                  lg="12"
                  md="12"
                  xs="12"
                >
                  <h1 className="title">We're undergoing renovation and will be back soon.</h1>
                  <p className="description">
                    Providing small businesses cost effective methods for hosting beautiful, 
                    intuitive, accessible, and eco-friendly web applications. 
                    A positive user experience for your customers is our primary goal.
                  </p>
                  <Button
                    color="success"
                    href="#pablo"
                    disabled
                    onClick={(e) => e.preventDefault()}
                    size="lg"
                  >
                    Learn More
                  </Button>
                </Col>
              </Row>
            </div>
</div>

    )
}